import { handleLogout } from "../../Pages/common/commonFunctions";
import { LOGGEDIN, LOGOUT, TOUR } from "./type";

const initialState = {
    isLoggedIN: false,
    tour: false,
};

const authReducer = (state = initialState, action: { type: string, payload: boolean }) => {
    const newState = { ...state };

    switch (action.type) {
        case LOGGEDIN:
            newState.isLoggedIN = true;
            break;

        case LOGOUT:
            handleLogout();
            newState.isLoggedIN = false;
            break;
        
        case TOUR:
            newState.tour = action.payload;
            break;
    }
    return newState;
};

export default authReducer;
import React, { MouseEventHandler } from 'react';

export function GlobalErrorFallBackUI({ error, resetErrorBoundary }: ErrorPropTypes) {
    return (
        <div className="container dead-center">
            <div className="row">
                <div className="col-md-12">
                    <div className="error-template">
                        <div className="d-flex align-items-center justify-content-center flex-column">
                            <h1 className="text-center">
                                Oops!
                            </h1>
                            <h6 className="text-center">
                                Something went wrong
                            </h6>
                            <div className="error-details">
                                {/* <pre>{error.message}</pre> */}
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                            <button onClick={(e) => {
                                // reset errors
                                resetErrorBoundary(e);
                                // reload page
                                window.location.reload();
                            }} className="btn btn-primary ml-20 mt-2">
                                <i className="fas fa-redo"></i>&nbsp;
                                Try Again
                            </button>
                            <button className="btn btn-secondary ml-20 mt-2">
                                <i className="fas fa-envelope"></i>&nbsp; Contact Support
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export interface ErrorPropTypes {
    error: Error,
    resetErrorBoundary: MouseEventHandler
}
import React from "react";
const Login = React.lazy(() => import("../Pages/Auth/Login"));
const Accounts = React.lazy(() => import("../Pages/Main/Accounts"));
const ThankYou = React.lazy(() => import("../Pages/common/ThankYou"));
const Logout = React.lazy(() => import("../Pages/Auth/logout"));

export const privateRoutes = [
  {
     path: "/",
    component: Accounts,

  },
  {
    path: "/accounts",
    component: Accounts,
  },
  {
    path: "/thankyou",
    component: ThankYou,
  },
  {
    path: "/logout",
    component: Logout,
  }
];

export const publicRoutes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  }
];

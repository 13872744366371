import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./root-reducer";
import thunk from 'redux-thunk';

// debugging  code 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers( applyMiddleware(thunk)));

// not debugging code 
// const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
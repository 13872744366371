import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import { headerAppsDropdownReducer } from "./headerAppsDropdown/reducer";
import { loadingReducer } from "./loader/reducer";
import { profileReducer } from "./profile/reducer";
import { firstTimeTourReducer } from "./Reactour/reducer";
import securityReducer from "./security/reducer";
import { toastReducer } from "./toast/reducer";
import userDetailsReducer from "./user/reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    toaster: toastReducer,
    userProfile: profileReducer,
    userDetails: userDetailsReducer,
    securityDetails: securityReducer,
    headerAppsDropdownReducer: headerAppsDropdownReducer,
    firstTimeTourReducer:firstTimeTourReducer,
});

export default rootReducer;

export interface IrootReducer {
    auth: any
    loading: any
    toaster: any
    userProfile: any
    userDetails: any
    securityDetails: any
    headerAppsDropdownReducer: any,
    firstTimeTourReducer: any
}
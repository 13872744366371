import { FIRSTTIMEVISITORMODAL, FIRSTTIMEVISITORTOURVISIBLE } from "./type";

const initialState = {
    visitorTourVisible: false,
    visitorModalVisible: true,
};

const firstTimeTourReducer = (state = initialState, action: { type: string, payload: boolean }) => {
    const newState = { ...state };
    switch (action.type) {
        case FIRSTTIMEVISITORMODAL:
            newState.visitorModalVisible = action.payload;
            break;
        case FIRSTTIMEVISITORTOURVISIBLE:
            newState.visitorTourVisible = action.payload;
            break;
    }
    return newState;
};

export { firstTimeTourReducer };


import { Suspense } from "react";
import { ErrorBoundary as GlobalErrorBoundary } from "react-error-boundary";
import "./App.css";
import "./assets/css/reactour.css";
import { GlobalErrorFallBackUI } from "./components/GlobalErrorFallBackUI";
import RoutingComponent from "./Routes/routing";

function App() {
  return (
    // @ts-ignore
    <GlobalErrorBoundary FallbackComponent={GlobalErrorFallBackUI}>
      <Suspense fallback={<h3>Loading...</h3>}>
        <RoutingComponent />
      </Suspense>
    </GlobalErrorBoundary>
  );
}

export default App;


import { getDecryptedLocalStorage } from "../../Pages/common/commonFunctions";
import { localStorageKeys } from "../../Pages/common/constants";
import { USER_PROFILE } from "./type";
const userData = getDecryptedLocalStorage(localStorageKeys.isLoggedIN);

const initialState = {
    userProfile: userData ? userData : {}
};

export const profileReducer = (state = initialState, action: { type: string, payload: any }) => {
    const newState = { ...state };
    switch (action.type) {
        case USER_PROFILE:
            newState.userProfile = action.payload;
    }

    return newState;
}
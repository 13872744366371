export const PublicRouteComponent = ({ Route, Redirect, publicRoutes, Switch }:any) => {
  return (
    <Switch>
      {publicRoutes.map((route:any, i:number) => {
        return (
          <Route exact={true} path={route.path} component={route.component} key={i} />
        );
      })}
      <Redirect to="/login" />
    </Switch>
  );
};

import { getDecryptedLocalStorage } from "../../Pages/common/commonFunctions";
import { localStorageKeys } from "../../Pages/common/constants";
import { USERDATA, NOTIFICATION_ON, TWOAUTH, AUTHENTICATOR } from "./type";

const initialState = {
  user: getDecryptedLocalStorage(localStorageKeys.isLoggedIN),
};

const userDetailsReducer = (
  state = initialState,
  action: {
    type: String;
    payload: any;
  }
) => {
  const newState = {
    ...state,
  };
  switch (action.type) {
    case USERDATA:
      newState.user = action.payload;
      break;
    case NOTIFICATION_ON:
      newState.user.notification = action.payload;
      break;
    case TWOAUTH:
      newState.user.twoAuth = action.payload;
      break;
    case AUTHENTICATOR:
      newState.user.authenticator = action.payload;
      break;
  }
  return newState;
};

export default userDetailsReducer;

export const defaultCountryCode = "ch";
export const toasterPosition = "bottom-left";
export const cookieExpiresInDays = 7;
export const onlyCharacterRegx = /^[a-zA-Z]+$/;
// 10 minute timer to set
export const QRExpireTIme = 2 * 60000;


export const ReactSelectCustomstyle = {
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#4c4f53", // Custom colour
    cursor: "pointer",
  }),
  control: (base: any) => ({
    ...base,
    borderColor: "#dadce0 !important",
    minHeight: 50,
    // This line disable the blue border
    boxShadow: "none",
    "&:hover": {
      borderColor: "#dadce0 !important",
    },
  }),
};

export const items = [
  {
    mainTitle: "Building the future ecosystem based on <strong>trust</strong>, <strong>security</strong> and <strong>transparency</strong>",
    subTitle: "edeXa Universe is the advanced next generation of networks. We make it simple for innovative businesses to adopt blockchain technology.",
    key: 0,
  },
  {
    mainTitle: "<strong>Ecosystem</strong>",
    subTitle: "Our ecosystem consists of out powerful, secure and energy-efficient Business Blockchain and is giving/offering you the build tools on a decentralized base with a reliable foundation.",
    key: 1,
  },
  {
    mainTitle: "<strong>EDX DApps</strong>",
    subTitle: "In our new ecosystem, we want to enable users to explore and find products with which they will want to engage and offer them an easy way obtain the key to their growth project.",
    key: 2,
  },
];

// qr code generator config
export const qrCodeConfig: any = {
  ecLevel: "M",
  size: 230,
  qrStyle: "squares",
  fgColor: "#0d0f12",
  logoHeightWidth: 70,
};

// kyc documents allowed file size
// 5 mb validation
export const KycDocumentMaxFileSize = 5 * 1024 * 1024;
// 300 kb
export const KycDocumentMinFileSize = 300 * 1024;


// Google Authenticator info timer
export const gAuthenticatorInfoTime = 15000

// types of the kyc documents
export const kycDocumentTypes = {
  passport: "passport",
  identityCard: "identity_card",
  drivingLicenece: "driving_licence",
  other: "other",
};
// documents number verification regular expressions
// ^[A-PR-WYa-pr-wy][1-9]\\d\\s?\\d{4}[1-9]$ - indian passport validation string
// https://www.geeksforgeeks.org/how-to-validate-indian-passport-number-using-regular-expression/
export const kycDocumentsNumberVerificationRegex = {
  passport: /^(?!^0+$)[A-Z0-9]{5,20}$/, //minimum 9 and max 9, allows alphabats and numbers as per international standards
  // validate if its a number or capital letter
  // accepts capital letters, minimum 3 and max 15
  identityCard: /^[A-Z0-9]{5,20}$/,
  // https://www.geeksforgeeks.org/how-to-validate-indian-driving-license-number-using-regular-expression/
  // this regex will validate indian driving license
  // example - UP14 20160034761 :=: [State name][state code than] [license year][last 7 is license number]
  drivingLicenece: /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  // regex to validate if its a number or an capital letter alphabet
  other: /^[A-Z0-9]{5,20}$/,
};

export const onlyCharacterValidationRegex = /^[aA-zZ '\s]+$/;
export const onlyNumberRegex = /^[0-9]*$/;
export const onlyCharacterWithLimit = /^[a-zA-z]+([\s][a-zA-Z]+)*$/; //minimum should be 3 and max can be 15 alphabets and numbers are allowed
// /^[a-zA-Z-_.' ]+$/
// eslint-disable-next-line
export const onlyNewpassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16}$)/;

export const kycDocumentsNumberPlaceholders = {
  passport: "AC9521907",
  identityCard: "QBZQRXY78956",
  drivingLicense: "215689654",
  other: "QBZQRXY78956",
};

export const AccountsKycFormValidationMessages = {
  name: {
    required: "Name is required",
    invalid: "Only alphabet are allowed",
  },
  firstName: {
    required: "First name is required",
    invalid: "Only alphabet are allowed",
  },
  lastName: {
    required: "Last name is required",
    invalid: "Only alphabet are allowed",
  },
  email: {
    required: "Email is required",
    invalid: "Enter a valid Email",
  },
  gender: {
    require: "Select a gender",
    invalid: "Selected gender is not valid",
  },
  countryOfOrigin: {
    required: "Country of origin is required",
    invalid: "Country of origin have invalid value",
  },
  countryOfResidence: {
    required: "Country of residence is required",
    invalid: "Residence country is not valid",
  },
  documentType: {
    required: "Select a document type*",
    invalid: "Invalid document type",
  },
  documentName: {
    required: "Document name is required",
    invalid: "Only alphabet are allowed",
  },
  documentNumber: {
    required: "Number is required",
    invalid: "Number is not valid",
  },
  // images data, it will be stored in base 64
  kycDocumentFront: {
    required: "Upload frontside of",
    invalid: "Only Images are allowed",
    maxSize: "Maximum size should be 150kb",
    maxResolution: "Image resolution is greater than 1080px",
  },
  kycDocumentBack: {
    required: "Upload backside of ",
    invalid: "Only Images are allowed",
    maxSize: "Maximum size should be 150kb",
    maxResolution: "Image resolution is greater than 1080px",
  },
  selfieWithDocument: {
    required: "Upload your selfie with",
    invalid: "Only Images are allowed",
    maxSize: "Maximum size should be 150kb",
    maxResolution: "Image resolution is greater than 1080px",
  },
};

export const localStorageKeys = {
  isLoggedIN: "isLoggedIN",
  userToken: "userToken",
  cookieToken: "cookieToken",
  deviceId: "deviceId",
  emailId: "emailId",
  cookieUser: "CookieUser",
};

export const cookieKeys = {
  cryptoSecretKey: "edexaUser",
  cookieInitial: "edexa",
  cookieUser: "CookieUser",
};

export const validationMessages = {
  email: {
    required: "E-Mail is required",
    invalid: "Enter a valid email",
  },
  password: {
    required: "Password is required",
    matches: "Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
  },
  regFullName: {
    required: "Full Name is required",
  },
  termsConditions: {
    required: "Terms of serive is required",
  },
  formInvalid: "Please fill in all the required fields.",
  invalidFile: "Please upload only images",
  currentPassword: {
    required: "Current password is required",
  },
  oldPassword: {
    required: "Old password is required",
  },
  newPassword: {
    required: "New password is required",
    notSame: "Old password and new password cannot be same",
  },
  confirmPassword: {
    required: "Confirm new password is required",
    mustMatch: "New password and confirm new password mismatched",
  },
  currentPasswordGoogle: {
    required: "Current password is required",
    mustMatch: "New password and Current new password mismatched",
  },
  onlyCharacters: "Only alphabets are allowed for this field",
  phone: {
    required: "Phone number is required",
    min: "Minimum 8 digits required",
    max: "Maximum 14 digits allowed"
  },
  otp: {
    required: "OTP number is required",
  },
  verification_code: {
    required: "Verification Code is required",
    invalid: "Only number are allowed for this field",
  },
  firstName: {
    required: "First Name is required",
  },
  lastName: {
    required: "Last Name is required",
  }
};

export const editFormDefaultValues = {
  gender: [
    { name: "Male", checked: true },
    { name: "Female", checked: false },
    { name: "Other", checked: false },
  ],
  year: { min: 1950, max: new Date().getFullYear() },
  monthList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
};

export const toasterMessages = {
  comingSoon: "Coming Soon",
};

export const notificationModalType = {
  DELETE_SINGLE: "delete_single",
  DELETE_ALL:"delete_all"
}
import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {  useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import LoaderComponent from "./loaderComponent";
import { PrivateRouteComponent } from "./PrivateRouteComponent";
import { PublicRouteComponent } from "./PublicRouteComponent";
import { privateRoutes, publicRoutes } from "./routes";

const  RoutingComponent = () => {
  const { isLoggedIn, isLoading } = useSelector((state: any) => ({
    isLoggedIn: state.auth.isLoggedIN,
    isLoading: state.loading.isLoading,
  }))

  useEffect(() => {
    window.addEventListener("message", (event) => {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
      if (event.origin !== "https://www.w3schools.com/")
        return;
      console.log("I'm receiving the data")
      // event.source is popup
      // event.data is "hi there yourself!  the secret response is: rheeeeet!"
    }, false)
  }, [])
  
  return (
    <>
      {isLoading && <LoaderComponent/>}
      <Toaster />
      <Suspense fallback={<LoaderComponent/>}>
        <Router>
          {isLoggedIn ? <PrivateRouteComponent Route={Route} Redirect={Redirect} privateRoutes={privateRoutes} Switch={Switch} />
           : <PublicRouteComponent Route={Route} Redirect={Redirect} publicRoutes={publicRoutes} Switch={Switch} />}
        </Router>
      </Suspense>
    </>
  );
}

export default RoutingComponent;

import * as _crypto from "crypto";
import { AES, enc, SHA512 } from "crypto-js";
import moment from "moment";
import toast from "react-hot-toast";
import history from "../common/history";
import { cookieExpiresInDays, cookieKeys, kycDocumentsNumberVerificationRegex, kycDocumentTypes, localStorageKeys, onlyNumberRegex, QRExpireTIme, toasterPosition } from "./constants";
import { getAudioFingerPrint } from "./getAudioFingerPrint";

const toastSuccess = (message: string) => {
  toast.success(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      minWidth: 150,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: "1px solid #073E84",
    },
    iconTheme: { primary: "#073E84 ", secondary: "#fff" },
  });
};

const toastError = (message: string) => {
  toast.error(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      fontWeight: 500,
      padding: 10,
      marginBottom: 60,
      border: "1px solid #ff0000",
    },
  });
};

const getUserDetails = () => {
  const isLoggedIN = getDecryptedLocalStorage(localStorageKeys.isLoggedIN) || "";
  if (isLoggedIN && isLoggedIN !== "") {
    return isLoggedIN;
  }
};

const redirectToEdexa = () => {
  window.open(process.env.REACT_APP_edexaDomain, "_blank");
};

// Validate documents number as per their type
// this function will return regex as per the document type
export const kycDocumentNumberValidationRegex = (documentType: string) => {
  // passport number validation regex
  if (documentType === kycDocumentTypes.passport) {
    return kycDocumentsNumberVerificationRegex.passport;
  } else if (documentType === kycDocumentTypes.drivingLicenece) {
    // Driving license verification regex
    // return kycDocumentsNumberVerificationRegex.drivingLicenece;
    return onlyNumberRegex;
  } else if (documentType === kycDocumentTypes.identityCard) {
    // identity card validation regex
    return kycDocumentsNumberVerificationRegex.identityCard;
  } else if (documentType === kycDocumentTypes.other) {
    // this will always validate if input is number or capital characters
    return kycDocumentsNumberVerificationRegex.other;
  }
};

// convert an image to base64
export const getBase64 = (file: any) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        // console.log(reader.result);
        return resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(false);
        // console.log("Error: ", error);
      };
    });
    // result.then((res) => console.log(res));
  } else {
    return new Promise((resolve, reject) => resolve(""));
  }
};
// base 64 function ends here

// @ Device support functions starts here
// check if camera is available or not
export function detectWebcam(callback: any) {
  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then((devices) => {
    callback(devices.some((device) => "videoinput" === device.kind));
  });
}
// use above function like this
// callback function for the detect webcame
// detectWebcam(function (hasWebcam: any) {
//   console.log("Webcam: " + (hasWebcam ? "yes" : "no"));
// });

// note: do not remove this code
// @ToDo: use a better approach this api is not supported in all browsers
// ToDo: this feature is experimental check browser compatiblity
// const isCameraPermissionsAreAllowed = async () => {
//   try {
//     const result = await navigator.permissions
//       .query({ name: "camera" })
//       .then((permissionStatus) => {
//         if (permissionStatus.state === "granted") {
//           return true;
//         } else {
//           return false;
//         }
//       });
//     return result;
//   } catch (error) {
//     console.log("error = ", error);
//     // return true temporarily if feature is not supported
//     return true;
//   }
// };

// @ Device support functions ends here

// @ country flags api starts here

// this api accepts country codes in small letters like - in and if in.svg then it will return an image of indian flag
export const countryFlag = (countryCode: string) => {
  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${countryCode.toLocaleLowerCase()}.svg`;
};

// country flag 2
// this api receives country codes in capital letters
export const countryFlag2 = (countryCode: string) => {
  return `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${countryCode.toLocaleUpperCase()}.svg`;
};

// @ country flags ends here

// call this function like this - validateFileSize(event.target.files[0], 1024)
export const validateFileSize = (file: any, size: number) => {
  // file will be the file boject
  if (file.size > size) {
    return false;
  } else {
    return true;
  }
};

export const setEncryptedLocalStorage = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    localStorage.setItem(keyName, encryptedString.toString());
  }
};

export const getDecryptedLocalStorage = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const localStorageData = localStorage.getItem(keyName);
    if (localStorageData) {
      return decryptData(localStorageData);
    } else {
      localStorage.clear();

      removeDecryptedCookie(cookieKeys.cookieUser);
    }
  }
};

export const setEncryptedCookie = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const date = new Date();
    const expiryTime = new Date(date.setTime(date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000)).toUTCString();
    document.cookie = `${keyName}=${encryptedString};expires=${expiryTime};domain=${window.location.hostname.replace("accounts", "")};secure;path=/;`;
  }
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};
export const getEncryptedCookieRawForm = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    return cookieData;
  }
};

export const removeDecryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=${window.location.hostname.replace("accounts", "")};path=/;`;
  }
};

export const encryptData = (data: any) => {
  return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey);
};

export const decryptData = (data: any) => {
  try {
    const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
    if (bytes.toString()) {
      const result = JSON.parse(bytes.toString(enc.Utf8));
      return result;
    }
  } catch (e) {
    localStorage.clear();
    removeDecryptedCookie(cookieKeys.cookieUser);
  }
};

/**
 * This functions working
 * @Param {null}
 * @return {Promise} - resolve(string)
 */
export const getDeviceId = () => {
  /***
   * @I userMediaFingerPrint
   * @II FingerPrintJs
   * @III canvasFingerPrint
   * @IV userAgentFingerPrint + canvasFingerPrint
   * @V WebRTC_FingerPrinting
   * @VI audioFingerprinting
   * @VII audio and canvas fingerprint
   */
  /**
   * @method 1
   * this method will get the user's device informations and generate a unique value
   * @drawback
   * 1. This function will return same value if another user has same config machine.
   * 2. If user changes browser plugins(extensions) then the value will also be changed.
   * 3. If user changes his monitor then this method will also give different result.
   * 4. mimeTypes is also depricated and will be removed from browsers in future
   *  @conclusion - this function should never be used
   */

  /* 
    let navigator_info = window.navigator;
    let screen_info = window.screen;
    let uid: any = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, "");
    uid += navigator_info.plugins.length;
    uid += screen_info.height || "";
    uid += screen_info.width || "";
    return (uid += screen_info.pixelDepth || "");
  */

  /**
   * It will generate unique value for each device always {particular browser}
   * @method 2
   */
  /**
   * This function will return a promise which result a string output
   * @fingerPrintJs - using fingerPrintJs library
   * @Param {null}
   * @return {Promise}
   */
  // return FingerprintJs.load()
  //   .then((response) => response.get())
  //   .then((result) => result.visitorId);

  /**
   * @method 3
   * @canvasFingerPrint
   * @Param {null}
   * @return {deviceId} - hashed value with sha512
   *
   * This method usages canvas based fingerprinting -
   * with this approach the statistics show that if you put this information together, your browser fingerprint will only match 1 in 286,777 others.
   * The alternate solution of this approach to return always unique value are
   * @first
   *  1. get the unique username of current user
   *  2. encrypt this value with the user as key
   *  3. return this value.
   * usagecase - usernames will be unique for each user and so our hashed value will be unique for each user.
   * For this approach to work we will need this function to take an string argument which will be the username value of the current logged-in user.
   * @second
   *  1. Combine method 1 and method 3 formulae
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const canvasFingerPrint = (function () {
    try {
      var canvas = document.createElement("canvas");
      var ctx: any = canvas.getContext("2d");
      var txt = "edeXa9accounts..$#edeXa((^@gdsSRTdfyt!~cz";
      ctx.textBaseline = "top";
      ctx.font = "16px 'Arial'";
      ctx.textBaseline = "alphabetic";
      ctx.rotate(0.05);
      ctx.fillStyle = "#f60";
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = "#069";
      ctx.fillText(txt, 2, 15);
      ctx.fillStyle = "rgba(102, 200, 0, 0.7)";
      ctx.fillText(txt, 4, 17);
      ctx.shadowBlur = 10;
      ctx.shadowColor = "blue";
      ctx.fillRect(-20, 10, 234, 5);
      var string = canvas.toDataURL();
      var hash = 0;
      if (string.length === 0) return "nothing!";
      for (let i = 0; i < string.length; i++) {
        hash = (hash << 5) - hash + string.charCodeAt(i);
        // 1. leftshift operation
        // 2. 0 + char =
        // 3. 0char - this will be converted to binary
        // 4. return to step 1
        hash = hash & hash;
        // bitwise and operation 1(1X1 = 0)
        //  the bit in the resulting binary representation is 1 (1 × 1 = 1); otherwise, the result is 0 (1 × 0 = 0 and 0 × 0 = 0)
        /**
         * example -
              0101 (decimal 5)
            AND 0011 (decimal 3)
              = 0001 (decimal 1)
         */
        // ref - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND
      }
      const hashedDeviceId = SHA512(hash?.toString()).toString(enc.Base64);
      return hashedDeviceId; //example - JxXleEyKh11uO0sQZoAF2ICnheXJiR0DVPAvp78qI/9ocQwE8hf8hiQu1EhK+v2L7GnePijKiu6Ygfhu/TY3uA==
    } catch (error) {
      /**
       * Catch Errors here
       */
      // fall back to default method if any error occurs
      let navigator_info = window.navigator;
      let screen_info = window.screen;
      let uid: any = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, "");
      uid += navigator_info.plugins.length;
      uid += screen_info.height || "";
      uid += screen_info.width || "";
      return SHA512((uid += screen_info.pixelDepth || "")).toString(enc.Base64);
    }
  })();
  // return canvasFingerPrint;

  /**
   * @method 4
   * @userAgentFingerPrint + @canvasFingerPrint
   * @Param {null}
   * @return {deviceId} - string
   * chances of uniqueness is increased by 1
   * This method is the combination fo the method 1 and method 2
   * usage case - canvas fingerprint + usernavigator value
   * drawback - if two peoples are uging same useragent then there is no meaning of using thsi method
   */
  // const userNavigator = window.navigator.userAgent.replace(/\D+/g, "");
  // return SHA256(currentUserDeviceId + userNavigator).toString(enc.Base64);

  /**
   * @method 5
   * @WebRTC_FingerPrinting
   * @param {null}
   * @return {peerConnectionUserId} - number
   *
   * Using webRtc to generate uniqueid
   * @browser_compatibility - https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection#browser_compatibility
   *
   * @drawbacks
   *  1. not supported in firefox and new versions of safari
   *  2. It generates unique Id for whole machine so if you change browser you Id will remain same.
   */
  // WebRTCUniqueId()
  //   .then((data) => {
  //     console.log(data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  /**
   * @method 6
   * @audioFingerprinting
   * @param {null}
   * @return {Promise} - number
   *
   * @pitfalls - https://fingerprintjs.com/blog/audio-fingerprinting/
   * except tor browser and brave browser it will work like a charm. Brave and tor are privacy concious browsers so they slightly modify the audio signals.
   */
  const audioFingerPrint = new Promise((resolve, reject) => {
    getAudioFingerPrint.run(function (fingerprint: any) {
      resolve(fingerprint);
    });
  });

  /**
   * @method 7 - @audioFingerPrinting + @canvasFingerPrinting
   * @Accuracy - high
   *
   * @param {null}
   * @return {Promise} - result sha512 hash5Kb+kh34lyLdojGH54E1B4RInTdpp9pwmKJgUJ8T7WgDuk13gAatlJ9DhWCAhejG5xgJnbj2KjQTr9PnwdFU1Q==
   */
  const AudioCanvasFingerPrint = new Promise((resolve, reject) => {
    audioFingerPrint.then((audioChannelResult) => {
      // resolve promise with sha512 hashing
      resolve(SHA512(canvasFingerPrint + audioChannelResult).toString(enc.Base64));
    });
  });
  return AudioCanvasFingerPrint;
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

const handleLogout = () => {
  localStorage.clear();

  removeDecryptedCookie(cookieKeys.cookieUser);

  // window.location.href = "/";

  history.push("/");
};

/**
 * @capitalize_first_char
 */
const capitalizeFirstLetter = (string: string) => {
  if (string) {  
    return string.toString().charAt(0).toUpperCase() + string.slice(1);
  }
  return null
}

/**
 * @format_text
 * @param {string} - first-name
 * @return {string} - First Name
 */

const getFormattedText = (text: string) => {
  if (text === "other") {
    return "Document";
  } else {
    try {
      return text.toString().split("_").map(item => capitalizeFirstLetter(item)).join(" ");
    } catch (error) {
      return capitalizeFirstLetter(text);
    }
  }
}



const encryptString = (string: string) => {
  let algo = process.env.REACT_APP_algorithm || "";
  let iv = process.env.REACT_APP_ivkey || "";
  let encryptedString = process.env.REACT_APP_enckey || "";

  try {
    let cipher = _crypto.createCipheriv(algo, encryptedString, iv);
    let encrypted = cipher.update(string);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('base64');
  } catch (error) {
    throw error
  }
}
export { toastSuccess, toastError, getUserDetails, redirectToEdexa, getCookie, handleLogout, capitalizeFirstLetter, getFormattedText, encryptString };


export const QR_Handle = (deviceDetailVal:any,deviceName:string,uuid:string, randomId:any) => {
   let currentDate = new Date();
  let expireTime = new Date(currentDate.getTime() + QRExpireTIme)
        deviceDetailVal.deviceName = deviceName;
        deviceDetailVal.deviceType = "2";
        deviceDetailVal.deviceToken = "null";
        deviceDetailVal.deviceId = uuid;
        deviceDetailVal.randomId = randomId;
        deviceDetailVal.qrAuth = true;
        deviceDetailVal.expireTime = expireTime.getTime()

  return encryptString(JSON.stringify(deviceDetailVal));

}

export const lastTimePasswordUpdate = (date:string) => {
    const newdate = new Date(date);
    const currentdate = new Date();
    if (newdate.getDate() === currentdate.getDate()) {
      let d = moment(date).format("h:mm A");
      return `Last changed ${d}`;
    } else if (newdate.getFullYear() !== currentdate.getFullYear()) {
      let d = moment(date).format("MMMM Do YYYY | h:mm A ");
      return `Last changed at ${d}`;
    } else {
      let d = moment(date).format(" MMM Do YYYY | h:mm A");
      return `Last changed at ${d}`;
    }
  };
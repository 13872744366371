import { HEADERAPPSDROPDOWNVISIBLE } from "./type";

const initialState = {
    headerAppsDropdownOpen: false
};

const headerAppsDropdownReducer = (state = initialState, action: { type: string, payload: boolean }) => {
    const newState = { ...state };
    switch (action.type) {
        case HEADERAPPSDROPDOWNVISIBLE:
            newState.headerAppsDropdownOpen = action.payload;
            break;
    }
    return newState;
};

export { headerAppsDropdownReducer };

